import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  readonly env = environment;

  @Input() isSmallScreen: boolean = false;
  @Input() user: any;
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();

  url = '';
  translator = inject(CustomTranslateService);
  router = inject(Router);

  foodURL = environment.FOOD_URL;

  ngOnInit(): void {
    this.url = this.router.url;
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
      },
    });
  }

  changeLanguage(language: string) {
    this.translator.setLanguage(language);
  }
}
