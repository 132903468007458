<div class="h-full flex gap-2 justify-between items-center px-6">
  <span class="flex items-center gap-2">
    <a [href]="foodURL" class="flex items-center gap-2 cursor-pointer">
      <img
        class="inline-block w-auto h-[1.875rem] object-contain object-center"
        src="assets/imgs/logo/{{ env.APP_LOGO }}.svg"
        alt="App Logo"
      />
      <h2 class="text-xl flex items-center gap-1">
        <span class="font-extrabold text-blue-600">{{
          env.APP_NAME | translate
        }}</span>
        <span class="text-lg font-medium">My wallet</span>
      </h2>
    </a>
  </span>

  <div class="flex items-center gap-4 lg:gap-6">
    <!-- <a
      [routerLink]="['account-balance']"
      pTooltip="Account balance"
      tooltipPosition="bottom"
      class="w-10 h-10 flex items-center justify-center hover:bg-gray-100 cursor-pointer rounded-lg"
      [ngClass]="{ 'bg-blue-50': url.startsWith('/account-balance') }"
    >
      <i
        class="sctr-icon-wallet-02 text-xl"
        [ngClass]="
          url.startsWith('/account-balance') ? 'text-blue-700' : 'text-gray-700'
        "
      ></i>
    </a> -->
    <app-flags-dropdown
      (languageChange)="changeLanguage($event)"
    ></app-flags-dropdown>

    <app-notifications></app-notifications>

    <div class="flex items-center gap-3">
      <img
        class="inline-block w-[1.875rem] h-[1.875rem] object-contain object-center rounded-full"
        [src]="user?.avatarURL"
        onerror="this.src='assets/imgs/default/avatar.webp'"
      />
      <div
        *ngIf="user?.full_name"
        class="grid text-sm font-semibold text-gray-700"
      >
        <span>{{ user?.full_name }}</span>
      </div>
    </div>
  </div>
</div>
