import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { CustomTranslateService } from './services/custom-translate.service';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [SentenceCasePipe],
  imports: [CommonModule, RouterModule],
  exports: [SentenceCasePipe],
  providers: [
    CustomTranslateService,
    TitleCasePipe,
    SentenceCasePipe,
    MessageService,
  ],
})
export class CoreModule {}
