import { MenuTreeComponent } from './components/menu-tree/menu-tree.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlagsDropdownComponent } from './components/dropdowns/flags-dropdown/flags-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from './components/button/button.component';
import { CoreModule } from '../core/core.module';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { PanelComponent } from './components/panel/panel.component';
import { SidebarModule } from 'primeng/sidebar';
import { CustomImageComponent } from './components/custom-image/custom-image.component';
import { HeaderComponent } from './components/header/header.component';
import { Countries } from '../core/models/classes/countries';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { TreeSelectModule } from 'primeng/treeselect';
import { InputTextModule } from 'primeng/inputtext';
import { SentenceCasePipe } from '../core/pipes/sentence-case.pipe';
import { CapitalizeFirstCasePipe } from '../core/pipes/capitalize-first-case.pipe';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { StatusTabComponent } from './components/status-tab/status-tab.component';
import { CustomPaginatorComponent } from './components/custom-paginator/custom-paginator.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast as CustomToast } from '../core/utils/toast.util';
import { ErrorHandler as CustomErrorHandler } from '../core/utils/error-handler.util';
import { AsteriskDirective } from './directives/asterisk.directive';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { DividerModule } from 'primeng/divider';
import { ProgressBarModule } from 'primeng/progressbar';
import { RangeDateComponent } from './components/range-date/range-date.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { TimeHandler } from '../core/utils/time-handler.util';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { HeaderNotificationsComponent } from './components/header/notifications/notifications.component';
import { BadgeModule } from 'primeng/badge';
import { AppLayoutsComponent } from './layouts/app-layouts/app-layouts.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@NgModule({
  declarations: [
    MenuTreeComponent,
    FlagsDropdownComponent,
    ButtonComponent,
    PanelComponent,
    CustomImageComponent,
    HeaderComponent,
    CapitalizeFirstCasePipe,
    StatusTabComponent,
    CustomPaginatorComponent,
    AsteriskDirective,
    DragAndDropDirective,
    UploadImageComponent,
    RangeDateComponent,
    ToastMessageComponent,
    PaginatorComponent,
    TableEmptyComponent,
    CalendarComponent,
    HeaderNotificationsComponent,
    AppLayoutsComponent,
    WelcomeComponent,
    AppLayoutsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DropdownModule,
    FormsModule,
    CoreModule,
    ButtonModule,
    FileUploadModule,
    ImageModule,
    SidebarModule,
    TranslateModule,
    DialogModule,
    TableModule,
    PaginatorModule,
    TreeSelectModule,
    InputTextModule,
    SkeletonModule,
    ToastModule,
    DividerModule,
    ProgressBarModule,
    OverlayPanelModule,
    CalendarModule,
    BadgeModule,
    ProgressSpinnerModule,
  ],
  exports: [
    MenuTreeComponent,
    FlagsDropdownComponent,
    ButtonComponent,
    PanelComponent,
    CustomImageComponent,
    HeaderComponent,
    TranslateModule,
    SentenceCasePipe,
    CapitalizeFirstCasePipe,
    ToastModule,
    CustomPaginatorComponent,
    StatusTabComponent,
    FormsModule,
    AsteriskDirective,
    DragAndDropDirective,
    UploadImageComponent,
    RangeDateComponent,
    OverlayPanelModule,
    CalendarModule,
    ToastMessageComponent,
    PaginatorComponent,
    TableEmptyComponent,
    CalendarComponent,
  ],
  providers: [
    Countries,
    SentenceCasePipe,
    MessageService,
    CustomToast,
    CustomErrorHandler,
    ConfirmationService,
    TimeHandler,
  ],
})
export class SharedModule {}
