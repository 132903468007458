import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { Toast as CustomToast } from './core/utils/toast.util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService, CustomToast],
})
export class AppComponent {
  readonly env = environment;
}
