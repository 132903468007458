import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  provideRouter,
  withComponentInputBinding,
} from '@angular/router';
import { AppLayoutsComponent } from './shared/layouts/app-layouts/app-layouts.component';
import { WelcomeComponent } from './shared/components/welcome/welcome.component';

const routes: Routes = [
  { path: 'welcome', component: WelcomeComponent },
  {
    path: '',
    component: AppLayoutsComponent,
    children: [
      {
        path: 'share-token',
        loadChildren: () =>
          import('./features/share-token/share-token.module').then(
            (m) => m.ShareTokenModule
          ),
      },

      {
        path: '',
        loadChildren: () =>
          import('./features/account-balance/account-balance.module').then(
            (m) => m.AccountBalanceModule
          ),
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [provideRouter(routes, withComponentInputBinding())],
})
export class AppRoutingModule {}
