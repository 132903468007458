import { Component, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { delay, of } from 'rxjs';
import { environment } from 'src/environments/environment';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};

@Component({
  selector: 'app-app-layouts',
  templateUrl: './app-layouts.component.html',
  styleUrls: ['./app-layouts.component.scss'],
})
export class AppLayoutsComponent {
  readonly smallScreen = 1024;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;

  user: any;
  stateView: string = STATE_RENDER_APP.INIT;
  sanitizer = inject(DomSanitizer);
  iframeUrl: SafeResourceUrl;
  isLoading = true;

  constructor() {
    window.addEventListener('message', this.handleReceiveMessage);
  }

  ngOnInit() {
    this.checkSmallScreen();
    window.addEventListener('resize', () => this.checkSmallScreen());

    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.IFRAME_CONNECT_URL
    );

    of(true)
      .pipe(delay(10000))
      .subscribe(() => {
        if (this.isLoading) {
          window.location.href = `${environment.LOGIN_URL}/login?r=${environment.FE_URL}`;
        }
      });
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };

  handleReceiveMessage = (e: any) => {
    if (e?.data?.type === 'authorized') {
      const accessTokenData = e.data?.token?.accessToken;
      const profileData = JSON.parse(e.data?.profile);
      if (accessTokenData && profileData) {
        localStorage.setItem('accessToken', accessTokenData);
        localStorage.setItem('auth', JSON.stringify(profileData));
        this.user = profileData;
        this.user.avatarURL = `${environment.BE_URL}storage/files/web/${this.user?.avatar_thumbnail_url}.webp`;
        this.stateView = STATE_RENDER_APP.APP;
        this.isLoading = false;
      } else {
        window.location.href = `${environment.LOGIN_URL}/login?r=${environment.FE_URL}`;
      }
    }
  };

  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }
}
